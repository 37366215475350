<template>
  <div>
    <!-- list filters -->
    <v-card>
      <v-card-title class="px-2">
        {{ $t('notify') }}
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="d-none d-md-block"
            :loading="processSendSms"
            :disabled="!selectedRows.length || processSendSms"
            @click="sendSMS"
          >
            <v-icon class="me-1">
              {{ icons.mdiEmailSendOutline }}
            </v-icon>
            <span class="d-none d-sm-block">{{ $t('send_link_to_sms') }}</span>
          </v-btn>
          <v-btn
            color="primary"
            fab
            outlined
            small
            class="d-block d-md-none"
            :loading="processSendSms"
            :disabled="!selectedRows.length || processSendSms"
            @click="sendSMS"
          >
            <v-icon>
              {{ icons.mdiEmailSendOutline }}
            </v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-row class="px-2">
        <!-- search filter -->
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="$t('wordingSearch')"
            :label="$t('search')"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <!-- customer status filter -->
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-select
            v-model="status_id"
            :placeholder="$t('status')"
            :label="$t('status')"
            :items="statusList"
            :item-text="$i18n.locale"
            item-value="value"
            outlined
            dense
          ></v-select>
        </v-col>

        <!-- customer group filter -->
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-autocomplete
            v-model="customer_group_id"
            :placeholder="$t('select_customer_group')"
            :label="$t('group_customers')"
            :items="customerGroupListData"
            item-text="customer_group_name"
            item-value="customer_group_id"
            outlined
            dense
          ></v-autocomplete>
        </v-col>
      </v-row>
      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        show-select
        :headers="tableColumns"
        :items="dataTableList"
        :options.sync="options"
        :server-items-length="totalDataTableList"
        :footer-props="footer"
        :loading="loading"
        item-key="customer_id_pri"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <!-- id -->
        <template #[`item.user_id`]="{ index }">
          {{ segmentId + index + 1 }}
        </template>

        <template v-slot:[`item.line_url`]="{ item }">
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="previewsQR(item)"
                v-on="on"
              >
                <v-icon>{{ icons.mdiQrcode }}</v-icon>
              </v-btn>
            </template>
            <span class="set-font-kanit">{{ $t('showQRcode') }}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.line_status_id`]="{ item }">
          <v-icon
            v-if="item.line_status_id == 3"
            color="success"
          >
            {{ icons.mdiCheck }}
          </v-icon>
          <v-icon
            v-if="item.line_status_id == 2"
            color="warning"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon>
          <v-icon
            v-if="item.line_status_id == 1"
            color="error"
          >
            {{ icons.mdiClose }}
          </v-icon>
        </template>
        <template #[`item.customer_fname`]="{ item }">
          <div>
            {{ item.customer_prefix }} {{ item.customer_fname }} {{ item.customer_lname }}
            <span v-if="item.customer_nname && item.customer_nname != 'null'">({{ item.customer_nname }})</span>
          </div>
        </template>

        <template #[`item.line_send_date`]="{ item }">
          <ConversDateTime :datetime="item.line_send_date" />
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data="options.page"
        :page-count="totalPage"
        :segment-id="+segmentId "
        :count-list="dataTableList.length"
        :total="+totalDataTableList"
        @pageChanged="(page)=>{options.page = page ; fetchDataTable()}"
        @itemChanged="(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}"
      />
    </v-card>
    <v-dialog
      v-model="dialogPreviewQR"
      max-width="300"
    >
      <v-card>
        <VueQrcode
          :value="lineUrl"
          :options="{ width: 300 }"
        ></VueQrcode>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiCheck, mdiClose, mdiAlertOutline, mdiEmailSendOutline, mdiQrcode } from '@mdi/js'
import { ref, defineComponent } from '@vue/composition-api'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import { i18n } from '@/plugins/i18n'
import customerGroup from '@/api/customer/customerGroup'
import useNotifyList from './useNotifyList'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import { formatPrice } from '@/plugins/filters'
import statusSendSMS from '@/@fake-db/data/statusSendSMS.json'
import ConversDateTime from '@/components/basicComponents/ConversDateTime.vue'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default defineComponent({
  filters: {
    formatPrice,
  },
  components: {
    VueQrcode,
    ConverseDate,
    Pagination,
    StatusBlock,
    ConversDateTime,
  },
  setup() {
    const customerGroupListData = ref([])
    const lineUrl = ref('')
    const dialogPreviewQR = ref(false)
    const statusList = ref(statusSendSMS.data)
    const { customerGroupList } = customerGroup
    const body = {
      searchtext: '',
      lang: i18n.locale,
      active_page: 1,
      per_page: '0',
    }
    customerGroupList(body).then(res => {
      const { data } = res
      customerGroupListData.value = data
      customerGroupListData.value.unshift({
        customer_group_id: '',
        customer_group_name: i18n.t('all'),
      })
    })

    const previewsQR = url => {
      lineUrl.value = url.line_url.replaceAll('\r', '').replaceAll('\n', '')
      dialogPreviewQR.value = true
    }

    return {
      dialogPreviewQR,
      lineUrl,
      customerGroupListData,
      statusList,
      previewsQR,
      ...useNotifyList(),

      // icons
      icons: {
        mdiCheck,
        mdiClose,
        mdiAlertOutline,
        mdiEmailSendOutline,
        mdiQrcode,
      },
    }
  },
})
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
