import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const customerGroupList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'customergroup/list',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('get customer group err : ', err)

      return []
    })

  return response
}
const addCustomerGroup = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'customergroup',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('add customer group err : ', err)

      return {}
    })

  return response
}
const updateCustomerGroup = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'customergroup',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update customer group err : ', err)

      return {}
    })

  return response
}

const deleteCustomerGroup = async id => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'customergroup',
      param: id,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update customer group err : ', err)

      return {}
    })

  return response
}
export default {
  customerGroupList, addCustomerGroup, updateCustomerGroup, deleteCustomerGroup,
}
