import customerNotify from '@/api/customer/customerNotify'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useNotifyList() {
  const processSendSms = ref(false)
  const searchQuery = ref('')
  const customer_group_id = ref('')
  const status_id = ref(4)
  const dataTableList = ref([])
  const tableColumns = [
    {
      text: 'Line',
      value: 'line_status_id',
      width: '50',
      align: 'center',
    },
    {
      text: 'QR Code',
      value: 'line_url',
      width: '100',
      align: 'center',
    },
    {
      text: i18n.t('customer_code'),
      value: 'customer_id',
      width: '100',
      align: 'center',
    },
    { text: i18n.t('firstname_lastname'), value: 'customer_fname', width: '220' },
    { text: i18n.t('group_customers'), value: 'customer_group_name', width: '120' },
    {
      text: i18n.t('tel'),
      value: 'customer_tel',
      width: '100',
      align: 'center',
    },
    { text: i18n.t('email'), value: 'customer_email', width: '150' },
    {
      text: i18n.t('send_link_to_sms'),
      value: 'line_send_date',
      width: '160',
      align: 'center',
    },
  ]
  const options = ref({})
  const loading = ref(false)
  const totalDataTableList = ref(0)
  const selectedRows = ref([])
  const segmentId = ref(0)
  const totalPage = ref(0)
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const { notifyList, sendSMSNotify } = customerNotify

  const sendSMS = () => {
    processSendSms.value = true
    const body = {
      select_checkbox: selectedRows.value.map(item => item.customer_id_pri),
      lang: i18n.locale,
    }
    sendSMSNotify(body).then(res => {
      store.commit('app/ALERT', {
        message: res.response ? 'completed_successfully' : 'unsuccessful',
        color: res.response ? 'success' : 'error',
      })
      processSendSms.value = false
      selectedRows.value = []
      fetchDataTable()
    })
  }

  const fetchDataTable = () => {
    loading.value = true
    const body = {
      searchtext: searchQuery.value,
      customer_group_id: customer_group_id.value,
      status_id: status_id.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }
    notifyList(body).then(res => {
      const { count, data, segment, count_of_page } = res
      dataTableList.value = data
      totalDataTableList.value = count
      totalPage.value = count_of_page
      segmentId.value = segment
      loading.value = false
    })
  }

  watch([searchQuery, status_id, customer_group_id, options], (newvalue, oldvalue) => {
    loading.value = true
    if (newvalue[2] !== oldvalue[2] || newvalue[1] !== oldvalue[1] || newvalue[0] !== oldvalue[0]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    processSendSms,
    searchQuery,
    customer_group_id,
    status_id,
    selectedRows,
    dataTableList,
    tableColumns,
    totalPage,
    options,
    loading,
    totalDataTableList,
    segmentId,
    footer,
    fetchDataTable,
    sendSMS,
  }
}
